import React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/SEO';
import { ImageGrid, ImageCaption } from '../styles/ImageGrid';
import { TripHeader } from '../components/TripHeader';
import Usoutsideroanokehh from '../assets/images/usoutsideroanokehh.jpg';
import Jackiewithbuddylee from '../assets/images/jackiewithbuddylee.jpg';
import Uswithchester from '../assets/images/uswithchester.jpg';
import Jacobwinterbuddyleejeanie from '../assets/images/jacobwinterbuddyleejeanie.jpg';
import Roanokehh from '../assets/images/roanokehh.jpg';

export default function Page() {
  return (
    <>
      <SEO title="Roanoke: 2-25-01" />
      <div>
        <TripHeader>
          <h2>Roanoke</h2>
          <p>2-25-01</p>
          <img
            src={Usoutsideroanokehh}
            alt="Stephen, Winter, Jeanie, Michael Wayne, Bailey, Patrick, COCK, Pooh & Hornsby at Roanoke Huddle House"
          />
          <ImageCaption>
            Stephen, Winter, Jeanie, Michael Wayne, Bailey, Patrick, COCK, Pooh
            & Hornsby at Roanoke Huddle House
          </ImageCaption>
        </TripHeader>
        <ImageGrid>
          <div>
            <img src={Jackiewithbuddylee} alt="Jackie with Buddy Lee" />
            <ImageCaption>Jackie with Buddy Lee</ImageCaption>
          </div>
          <div>
            <img
              src={Uswithchester}
              alt="Hornsby, Bailey, and Jacob with Chester"
            />
            <ImageCaption>Hornsby, Bailey, and Jacob with Chester</ImageCaption>
          </div>
          <div>
            <img
              src={Jacobwinterbuddyleejeanie}
              alt="Jacob, Winter, Buddy Lee, & Jenni"
            />
            <ImageCaption>Jacob, Winter, Buddy Lee, & Jenni</ImageCaption>
          </div>
        </ImageGrid>
        <p>
          Well we went to the Huddle House again after church. Michael Wayne,
          Jenni, Winter, Stephen, Patrick, Bailey, COCK, Pooh, and Hornsby tried
          out the Roanoke Huddle House, and I must say we weren't disapointed.
          We all had a lot of fun. Jacob "COCK", Jeanie, and Stephen went for
          the first time. Check them out in the "
          <Link to="/members">Members</Link>" section. We meet Jackie, the
          waitress, and of course she was nice as she could be. We also meet a
          great big guy named chester. We became great friends with him. Chester
          and Jackie are on the "<Link to="/friends">Friends</Link>" section.
          The food was great and the people friendly--it was perfect--but of
          course Huddle House just about always is!
        </p>
        <ImageGrid>
          <div>
            <img src={Roanokehh} alt="Roanoke Huddle House" />
            <ImageCaption>Roanoke Huddle House</ImageCaption>
          </div>
        </ImageGrid>
      </div>
    </>
  );
}
